<template>
    <div class="bbb">
        <div id="Interrogation" class="Drugs time_slot">
            <video ref="video" id="video" class="video vjs-fluid" autoplay></video>
            <div v-show="tipShow" class="tip">{{tipMsg}}</div>
            <div  class="tip">{{textContent}}</div>
        </div>
    </div>
  </template>
  <script>
    // import { MessageBox } from 'mint-ui'
    import { BrowserMultiFormatReader } from '@zxing/library'
    export default {
        name: 'qr-code-search',
        components: {},
        data() {
        return {
            loadingShow: false,
            codeReader: new BrowserMultiFormatReader(),
            textContent: null,
            vin: null,
            tipMsg: '正在尝试识别....',
            tipShow: false
        }
        },
        created() {
        // this.tipShow = true
        this.openScan()
        },
        methods: {
        async openScan() {
            const that = this
            that.codeReader.getVideoInputDevices().then((videoInputDevices) => {
            that.tipShow = true
            that.tipMsg = '正在调用后置摄像头...'
            // 默认获取第一个摄像头设备id
            let firstDeviceId = videoInputDevices[0].deviceId;
            // 获取第一个摄像头设备的名称
            const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
            if (videoInputDevices.length > 1) {
                // 判断是否后置摄像头
                if (videoInputDeviceslablestr.indexOf('back') > -1) {
                firstDeviceId = videoInputDevices[0].deviceId;
                } else {
                firstDeviceId = videoInputDevices[1].deviceId;
                }
            }
            that.decodeFromInputVideoFunc(firstDeviceId)
            }).catch((err) => {
            that.tipShow = false
            console.error(err);
            });
        },
        async openScanTwo() {
            const that = this
            // codeReader.reset() // 重置
            // that.textContent = null // 重置
            that.codeReader = await new BrowserMultiFormatReader()
            that.codeReader.getVideoInputDevices().then((videoInputDevices) => {
            that.tipShow = true
            that.tipMsg = '正在调用后置摄像头...'
            // 默认获取第一个摄像头设备id
            let firstDeviceId = videoInputDevices[0].deviceId;
            // 获取第一个摄像头设备的名称
            const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
            if (videoInputDevices.length > 1) {
                // 判断是否后置摄像头
                if (videoInputDeviceslablestr.indexOf('back') > -1) {
                firstDeviceId = videoInputDevices[0].deviceId;
                } else {
                firstDeviceId = videoInputDevices[1].deviceId;
                }
            }
            that.decodeFromInputVideoFunc(firstDeviceId)
            }).catch((err) => {
            that.tipShow = false
            console.error(err);
            });
        },
        decodeFromInputVideoFunc(firstDeviceId) {
            const that = this
            that.codeReader.reset() // 重置
            that.textContent = null // 重置
            that.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
            that.tipMsg = '正在尝试识别...'
            // let nowContent = null
            that.textContent = null
            if (result) {
                this.$notify({
                    title: '提示',
                    message: result,
                    duration: 0
                    });

                that.textContent = result;
                if (that.textContent) {
                that.tipShow = false
                that.msgBoxFunc(that.textContent)
                }
            }
            if (err && !(err)) {
                that.tipMsg = '识别失败'
                setTimeout(() => {
                that.tipShow = false
                },2000)
                console.error(err);
            }
            });
        },
        // that.$createDialog 是 cube-ui滴滴 messageBox ,到这一步 二维码值已出，这里是可有可无的代码块，看各自项目ui使用情况 自行替换 messageBox
        msgBoxFunc(textContent) {
            const that = this
            // alert('8执行了 msgBoxFunc(),textContent:' + textContent)
            // cube-ui messageBox
            that.$createDialog({
            type: 'prompt',
            // icon: 'cubeic-alert',
            title: '识别内容',
            // content: that.textContent,
            prompt: {
                value: textContent,
                placeholder: '请输入'
            },
            confirmBtn: {
                text: '确定内容',
                active: true,
                disabled: false,
                href: 'javascript:;'
            },
            cancelBtn: {
                text: '继续识别',
                active: false,
                disabled: false,
                href: 'javascript:;'
            },
            onConfirm: (e, promptValue) => {
                // that.hide()
                that.vin = promptValue
            },
            onCancel: () => {
                that.$nextTick(()=>{
                that.openScanTwo()
                })
            }
            }).show()
        },
        }
    }
  </script>
  <style scopde lang="less">
   .video{
    /*border: 1px solid gray;*/
    margin-top: .5rem;
    /*width: 2.6rem;*/
    /*height: 3rem;*/
  }
  .tip{
    color: #000;
    font-size: 50px;
  }
  /* common */
  .bgc-f4f4f4{
    background-color: #363636;
  }
  .page{
    overflow-y: auto;
    position: relative;
  }

</style>